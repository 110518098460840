import { FaAward } from 'react-icons/fa'
import { GiTeacher } from "react-icons/gi";
import { BiHappyHeartEyes } from 'react-icons/bi'


const data = [
    { id: 1, icon: <FaAward />, title: 'Experiencia', desc: '15+ años,' },
    { id: 2, icon: <GiTeacher />, title: 'Tutorias', desc: '5+ años' },
    { id: 3, icon: <BiHappyHeartEyes />, title: 'Clientes', desc: '100% felices' }
]



export default data;