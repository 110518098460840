import Image1 from '../../assets/top-left.png'
import Image2 from '../../assets/top-right.png'
import Image3 from '../../assets/bottom-left.png'
import Image4 from '../../assets/bottom-right.png'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
    return (
        <section id="sobre_mi" data-aos="fade-in">
            <div className="container about__container">
                <div className="about__left">
                    <div className="headerRight">
                        <article className="card1">
                            <img src={Image1} alt='' />
                        </article>
                        <article className="card2">
                            <img src={Image2} alt='' />
                        </article>
                        <article className="card3">
                            <img src={Image3} alt='' />
                        </article>
                        <article className="card4">
                            <img src={Image4} alt='' />
                        </article>
                    </div>
                </div>
                <div className="about__right">
                    <h2>Sobre Mí</h2>
                    <div className="about__cards">
                        {
                            data.map(item => (
                                <Card key={item.id} className="about__card">
                                    <span className='about__card-icon'>{item.icon}</span>
                                    <h5>{item.title}</h5>
                                    <small>{item.desc}</small>
                                </Card>
                            ))
                        }
                    </div>
                    <p>
                        Hola, soy Glimar Azuaje, Licenciada en Educación Integral y Especialista en Planificación y Evaluación Educativa. Mi pasión por la educación y el desarrollo infantil me ha llevado a crear ambientes de aprendizaje positivos donde cada niño pueda alcanzar su máximo potencial.
                    </p>
                    <p>
                        Cuento con más de 15 años de experiencia en la enseñanza y más de 5 años como tutora particular. He colaborado con diversas instituciones educativas, enriqueciendo mi visión y técnicas pedagógicas.
                    </p>
                    <p>
                        Mi filosofía se centra en el desarrollo integral del estudiante, atendiendo tanto sus necesidades académicas como emocionales. Creo en el aprendizaje personalizado y en adaptarme a los diferentes estilos de aprendizaje para asegurar el éxito académico y personal de cada niño.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default About
