import { FaChalkboardTeacher } from "react-icons/fa";
import { BiSolidBookReader } from "react-icons/bi";
import { PiMathOperationsFill } from "react-icons/pi";
import { LuNotebookPen } from "react-icons/lu";


const data = [
    {
        id: 1, icon: <FaChalkboardTeacher />, title: 'Tutorías Personalizadas', desc: "Enfoque individualizado que atiende las necesidades específicas del alumno, adaptando el plan de estudio y métodos de enseñanza para maximizar su rendimiento académico."
    },
    {
        id: 2, icon: <BiSolidBookReader />, title: 'Clases de Refuerzo', desc: "Sesiones adicionales para ayudar a los estudiantes a comprender mejor los conceptos difíciles, proporcionando un entorno más atento y personalizado."
    },
    {
        id: 3, icon: <PiMathOperationsFill />, title: 'Reforzamiento Matemático', desc: "Programas dedicados a fortalecer habilidades matemáticas mediante la práctica de problemas y explicación de conceptos fundamentales."
    },
    {
        id: 4, icon: <LuNotebookPen />, title: 'Lectoescritura', desc: 'Proceso integral de enseñanza de la lectura y escritura, desarrollando habilidades básicas como la decodificación, comprensión lectora y expresión escrita.'
    }
]


export default data