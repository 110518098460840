const data = [
    {
        id: 1,
        question: "¿Cuál es el enfoque de las tutorías particulares?",
        answer: "El enfoque de las tutorías particulares es proporcionar apoyo educativo personalizado a cada estudiante, adaptando las lecciones a sus necesidades individuales y estilos de aprendizaje para mejorar su rendimiento académico."
    },
    {
        id: 2,
        question: "¿Qué materias se cubren en las tutorías?",
        answer: "Ofrezco tutorías en una variedad de materias, incluyendo matemáticas, lectoescritura, ciencias, estudios sociales y más. También puedo ayudar con tareas y preparación para exámenes."
    },
    {
        id: 3,
        question: "¿Cómo se estructura una sesión de tutoría?",
        answer: "Cada sesión de tutoría se estructura según las necesidades del estudiante. Generalmente, comenzamos con una revisión de los conceptos previos, luego abordamos los temas actuales y finalizamos con ejercicios prácticos y una breve evaluación."
    },
    {
        id: 4,
        question: "¿Qué tipo de materiales se utilizan durante las sesiones?",
        answer: "Utilizo una variedad de materiales educativos, como libros de texto, hojas de trabajo, recursos en línea y manipulativos. También incorporo actividades lúdicas y juegos didácticos para hacer el aprendizaje más interactivo y divertido."
    },
    {
        id: 5,
        question: "¿Cómo se evalúa el progreso del estudiante?",
        answer: "Realizo evaluaciones periódicas para monitorear el progreso del estudiante y ajustar el plan de estudio según sea necesario. También mantengo comunicación regular con los padres para informarles sobre el avance de su hijo."
    },
    {
        id: 6,
        question: "¿Cuál es la duración y la frecuencia de las sesiones?",
        answer: "La duración y frecuencia de las sesiones de tutoría se pueden personalizar según las necesidades del estudiante y la disponibilidad de los padres. Generalmente, las sesiones duran entre 60 y 90 minutos y se realizan una o dos veces por semana."
    },
    {
        id: 7,
        question: "¿Ofreces tutorías en línea o solo presenciales?",
        answer: "Ofrezco tanto tutorías en línea como presenciales, dependiendo de la preferencia y conveniencia de los padres y el estudiante. Las tutorías en línea se realizan a través de plataformas de videoconferencia seguras y fáciles de usar."
    },
    {
        id: 8,
        question: "¿Qué experiencia tienes como maestra y tutora?",
        answer: "Tengo más de 15 años de experiencia como maestra de primaria y he trabajado como tutora particular durante más de 5 años. Mi formación académica incluye Licenciatura en Educación Integral, Especialización en Planificación y Evaluación Educativa, y he asistido a numerosos cursos y talleres de desarrollo profesional."
    },
    {
        id: 9,
        question: "¿Cómo se personalizan las tutorías para cada estudiante?",
        answer: "Personalizo las tutorías basándome en una evaluación inicial de las necesidades y habilidades del estudiante. Creo un plan de estudio individualizado que aborda sus fortalezas y áreas de mejora, y adapto las lecciones para mantener el interés y la motivación del estudiante."
    },
    {
        id: 10,
        question: "¿Cuál es el costo de las sesiones de tutoría?",
        answer: "El costo de las sesiones de tutoría varía según la duración y frecuencia de las sesiones. Para obtener una cotización personalizada, por favor, póngase en contacto conmigo a través de cualquiera de las opciones de la lista a continuación."
    }
]

export default data