import data from './data';
import { IoSchool } from "react-icons/io5";
//import { useModalContext } from '../../context/modal-context';
import './navbar.css';

const Navbar = () => {
    return (
        <nav>
            <div className="container nav__container">
                <div className='nav__logo'>AGLIMAR</div>
                <ul className='nav__menu'>
                    {data.map(item => (
                        <li key={item.id}>
                            <a href={item.link}>{item.title}</a>
                        </li>
                    ))}
                </ul>
                <h3 id='theme__icon'><IoSchool /></h3>
            </div>
        </nav>
    );
}

export default Navbar;
