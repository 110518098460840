import { useEffect } from 'react'
import HeaderImage from '../../assets/header.jpg'
//import data from './data'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './header.css'

const Header = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 })
    }, [])

    return (
        <header id="header">
            <div className="container header__container">
                <div className="header__profile" data-aos="fade-in">
                    <img src={HeaderImage} alt="Header Portait" />
                </div>
                <h2 data-aos="fade-up">Glimar Azuaje</h2>
                <p data-aos="fade-up">
                    Licenciada en Educación Integral / Especialista en Planificación y Evaluación Educativa.
                </p>
                <div className="header__cta" data-aos="fade-up">
                    <a href="#contacto" className='btn primary'>Hablemos</a>
                    <a href="#servicios" className='btn light'>Servicios</a>
                </div>
                {/*<div className="header__socials">
                    {
                        data.map(item => <a key={item.id} href={item.link} target="_blank" rel="noopener noreferrer">{item.icon}</a>)
                    }
                </div>*/}
            </div>
        </header>
    )
}

export default Header
